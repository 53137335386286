import { useEffect, useState } from 'react';
import ToastNotification, { NotificationType } from './ToastNotification';
import { ReactChildren } from '../../utils';

type Props = {
    setNotify: (notifyFn: (notification: IToastNotification) => void) => void;
};

export const NOTIFICATION_POSITION = {
    TOP: 'TOP',
    RIGHT: 'RIGHT',
};

export interface IToastNotification {
    type: NotificationType;
    autoClose?: boolean;
    children: ReactChildren;
    id?: number;
    position: keyof typeof NOTIFICATION_POSITION;
}

export default function ToastNotificationsManager({ setNotify }: Props) {
    const [notifications, setNotifications] = useState<IToastNotification[]>([]);

    const openNotification = (notification: IToastNotification) => {
        setNotifications((prevNotifications) => {
            return [
                ...prevNotifications,
                {
                    ...notification,
                    id: Date.now(),
                },
            ];
        });
    };

    useEffect(() => {
        setNotify((notification: IToastNotification) => openNotification(notification));
    }, [setNotify]);

    const closeNotification = (id: number) => {
        setNotifications((prevNotifications) => prevNotifications.filter((x) => x.id !== id));
    };

    return (
        <>
            <div className="pointer-events-none fixed inset-x-0 top-5 mx-auto flex w-fit flex-col justify-center">
                {notifications
                    .filter((x) => x.position === NOTIFICATION_POSITION.TOP)
                    .map(({ id, ...props }) => (
                        <ToastNotification key={id} onClose={() => closeNotification(id!)} {...props} />
                    ))}
            </div>
            <div className="pointer-events-none fixed inset-y-0 right-9 top-60 z-50 my-auto flex w-fit flex-col space-y-4">
                {notifications
                    .filter((x) => x.position === NOTIFICATION_POSITION.RIGHT)
                    .map(({ id, ...props }) => (
                        <ToastNotification key={id} onClose={() => closeNotification(id!)} {...props} />
                    ))}
            </div>
        </>
    );
}
