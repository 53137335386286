import ToastNotificationsManager, { IToastNotification, NOTIFICATION_POSITION } from './ToastNotificationsManager';
import ToastNotification, { NotificationType } from './ToastNotification';
import createContainer from './createContainer';
import { createRoot } from 'react-dom/client';
import { ReactChildren } from '../../utils';

let notify: (notification: IToastNotification) => void;

const containerElement = createContainer();
const containerRoot = createRoot(containerElement);

containerRoot.render(
    <ToastNotificationsManager
        setNotify={(notifyFn) => {
            notify = notifyFn;
        }}
    />
);

export { ToastNotification, NotificationType };

export const infoToast = (
    children: ReactChildren,
    position: keyof typeof NOTIFICATION_POSITION = 'TOP',
    autoClose?: boolean
) => {
    return notify({
        type: NotificationType.Info,
        children,
        autoClose,
        position,
    });
};

export function successToast(
    children: ReactChildren,
    position: keyof typeof NOTIFICATION_POSITION = 'TOP',
    autoClose?: boolean
) {
    return notify({
        type: NotificationType.Success,
        children,
        autoClose,
        position,
    });
}

export function warningToast(
    children: ReactChildren,
    position: keyof typeof NOTIFICATION_POSITION = 'TOP',
    autoClose?: boolean
) {
    return notify({
        type: NotificationType.Warning,
        children,
        autoClose,
        position,
    });
}

export function errorToast(
    children: ReactChildren,
    position: keyof typeof NOTIFICATION_POSITION = 'TOP',
    autoClose?: boolean
) {
    return notify({
        type: NotificationType.Error,
        children,
        autoClose,
        position,
    });
}
