export default () => {
    const portalId = 'notificationContainer';
    let element = document.getElementById(portalId);

    if (element) {
        return element;
    }

    element = document.createElement('div');
    element.setAttribute('id', portalId);
    document.body.appendChild(element);
    return element;
};
