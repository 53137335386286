import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { ReactChildren } from '../utils';

type Props = {
    children: ReactChildren;
    onCloseClick: () => void;
    theme: {
        borderColor: string;
        iconBackgroundColor: string;
        iconColor: string;
        textColor: string;
    };
};

export default function Toast({ children, onCloseClick, theme }: Props) {
    const { borderColor, iconBackgroundColor, iconColor, textColor } = theme;

    return (
        <div
            className={`${borderColor} pointer-events-auto flex items-center justify-between border-l-[6px] bg-white p-4 shadow-lg`}
        >
            <div className="flex items-center">
                <div className={`mr-2.5 flex h-8 w-8 items-center justify-center rounded-full ${iconBackgroundColor}`}>
                    <CheckIcon className={`h-5 w-5 ${iconColor}`} />
                </div>
                <div className={`caption ${textColor}`}>{children}</div>
            </div>
            <button className="ml-2" onClick={onCloseClick}>
                <XMarkIcon className="h-[18px] w-[18px] text-black" />
            </button>
        </div>
    );
}
