import { useEffect } from 'react';
import Toast from '../Toast';
import { ReactChildren } from '../../utils';

export enum NotificationType {
    Success,
    Info,
    Warning,
    Error,
}

type Props = {
    type: NotificationType;
    children?: ReactChildren;
    autoClose?: boolean;
    onClose: () => void;
};

export const ToastTheme = {
    [NotificationType.Success]: {
        borderColor: 'border-green-500',
        iconBackgroundColor: 'bg-green-50',
        iconColor: 'text-green-500',
        textColor: 'text-black',
    },
    [NotificationType.Error]: {
        borderColor: 'border-red-700',
        iconBackgroundColor: 'bg-red-50',
        iconColor: 'text-red-700',
        textColor: 'text-red-700',
    },
    [NotificationType.Info]: {
        borderColor: 'border-green-500',
        iconBackgroundColor: 'bg-green-50',
        iconColor: 'text-green-500',
        textColor: 'text-black',
    },
    [NotificationType.Warning]: {
        borderColor: 'border-red-700',
        iconBackgroundColor: 'bg-red-50',
        iconColor: 'text-red-700',
        textColor: 'text-red-700',
    },
};

export default function ToastNotification({
    type = NotificationType.Success,
    children,
    autoClose = true,
    onClose,
}: Props) {
    const timeToClose = 5000;

    useEffect(() => {
        if (autoClose) {
            const timeoutId = setTimeout(onClose, timeToClose);

            return () => clearTimeout(timeoutId);
        }
    }, [autoClose]);

    return (
        <Toast theme={ToastTheme[type]} onCloseClick={onClose}>
            {children}
        </Toast>
    );
}
